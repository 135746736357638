import { DataCard } from './DataCard';

import { GeneralProps } from '../type';
import { displayMoney } from '../utils/displayMoney';
import { safeParseFloat } from '../utils/safeParseFloat';

type Props = {
  general: GeneralProps;
};

export const ListCard: React.FC<Props> = ({ general }) => (
  <div className="shrink-0 grid grid-cols-1 tablet:grid-cols-2 sm-desktop:grid-cols-3 gap-xs">
    <DataCard title="CDP" timeData="week">
      <div className="text-ds-h5 lg-desktop:text-ds-h4">
        <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
          {general.nb_mer_cdp_sem}{' '}
        </span>
        leads avec leur première <span className="font-bold">consultation </span>
      </div>
    </DataCard>
    <DataCard title="CDPL" timeData="week">
      <div className="text-ds-h5 lg-desktop:text-ds-h4">
        <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
          {general.nb_mer_cdpl_sem}{' '}
        </span>
        leads avec leur première <span className="font-bold">consultation </span>
      </div>
    </DataCard>
    <DataCard title="Club pro">
      <div className="text-ds-h5 lg-desktop:text-ds-h4">
        <div className="mb-xs">
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {displayMoney(safeParseFloat(general.mrr_club_pro))}
          </span>{' '}
          de MRR
        </div>
        <div>
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {general.nb_clients_club_pro}
          </span>{' '}
          clients à date
        </div>
      </div>
    </DataCard>
    <DataCard
      title="CDP"
      timeData="month"
      subTitle={
        <>
          Objectif revenus:{' '}
          <span className="text-primary font-bold">
            {displayMoney(safeParseFloat(general.obj_rev_cdp))}
          </span>
        </>
      }
      secondSubTitle={
        <>
          Objectif GMV:{' '}
          <span className="text-primary font-bold">{displayMoney(general.obj_gmv_cdp)}</span>
        </>
      }
    >
      <div className="text-ds-h5 lg-desktop:text-ds-h4">
        <div className="mb-xs">
          {' '}
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {displayMoney(safeParseFloat(general.revenus_cdp_mois))}
          </span>{' '}
          de revenus
        </div>

        <div className="mb-xs">
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {displayMoney(safeParseFloat(general.gmv_cdp_mois))}
          </span>{' '}
          de GMV
        </div>
        <div className="mb-xs">
          {' '}
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {general.take_rate_cdp?.toFixed(1)}%
          </span>{' '}
          Take rate
        </div>
      </div>
    </DataCard>
    <DataCard
      title="CDPL"
      timeData="month"
      subTitle={
        <>
          Objectif revenus:{' '}
          <span className="text-primary font-bold">
            {displayMoney(safeParseFloat(general.obj_rev_cdpl))}
          </span>
        </>
      }
      secondSubTitle={
        <>
          Objectif GMV:{' '}
          <span className="text-primary font-bold">{displayMoney(general.obj_gmv_cdpl)}</span>
        </>
      }
    >
      <div className="text-ds-h5 lg-desktop:text-ds-h4">
        <div className="mb-xs">
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {displayMoney(safeParseFloat(general.revenus_cdpl_mois))}
          </span>{' '}
          de revenus
        </div>
        <div className="mb-xs">
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {displayMoney(safeParseFloat(general.gmv_cdpl_mois))}
          </span>{' '}
          de GMV
        </div>
        <div className="mb-xs">
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {general.take_rate_cdpl?.toFixed(1)}%
          </span>{' '}
          Take rate
        </div>
      </div>
    </DataCard>
    <DataCard title="TP x ARCHI" timeData="month">
      <div className="text-ds-h5 lg-desktop:text-ds-h4">
        <div className="mb-xs">
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {displayMoney(safeParseFloat(general.gmv_aint_aext_bet_mois))}{' '}
          </span>{' '}
          GMV archi externe x interne et BET
        </div>
        <div className="mb-xs">
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {displayMoney(safeParseFloat(general.comm_aint_aext_bet_mois))}{' '}
          </span>{' '}
          Revenus archi externe x interne et BET
        </div>
        <div>
          <span className="text-primary font-bold text-ds-h4 lg-desktop:text-ds-h3">
            {displayMoney(safeParseFloat(general.tp_fees_mois))}
          </span>{' '}
          Revenus TP
        </div>
      </div>
    </DataCard>
  </div>
);
