import classNames from 'classnames';

import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';

import { FloatingScore } from './components/FloatingScore';
import './zgeg.css';
import { zgegData } from './zgegData';

const zgegPodiumSize = {
  first: 'text-ds-h2 bg-warning-500 h-xl p-xl',
  second: 'text-ds-h4 bg-neutral-400 h-lg p-lg',
  third: 'text-sm bg-error-500 h-md p-md',
};

const classNamesZgegPodium = 'flex flex flex-col items-center justify-center';

const yearsToFilterForPodium = ['18'];

const findMemberWithMostZeros = (): { worst3: JSX.Element[]; top3: JSX.Element[] } => {
  const zgegDataFiltered = zgegData.filter(game => {
    const gameYear = game.date.split('/')[2];
    return !yearsToFilterForPodium.includes(gameYear);
  });
  const results = zgegDataFiltered.reduce((acc: { [key: string]: number }, game) => {
    const losingTeam = game.team2;
    const namesLosingTeam = losingTeam.filter(item => typeof item === 'string');

    namesLosingTeam.forEach((name: string) => {
      if (acc[name]) {
        acc[name] = acc[name] + 1;
      } else {
        acc[name] = 1;
      }
    });
    return acc;
  }, {});

  const resultsBis = zgegDataFiltered.reduce((acc: { [key: string]: number }, game) => {
    const winningTeam = game.team1;
    const namesWinningTeam = winningTeam.filter(item => typeof item === 'string');

    namesWinningTeam.forEach((name: string) => {
      if (acc[name]) {
        acc[name] = acc[name] + 1;
      } else {
        acc[name] = 1;
      }
    });
    return acc;
  }, {});

  const sortedEntries = Object.entries(results).sort((a, b) => b[1] - a[1]);
  const worst3 = sortedEntries.slice(0, 3);

  const sortedEntriesBis = Object.entries(resultsBis).sort((a, b) => b[1] - a[1]);
  const top3 = sortedEntriesBis.slice(0, 3);

  return {
    top3: top3.map(([name, score]) => (
      <div>
        {name} - {score}
      </div>
    )),
    worst3: worst3.map(([name, score]) => (
      <div>
        {name} - {score}
      </div>
    )),
  };
};

const { top3, worst3 } = findMemberWithMostZeros();

export const Zgeg: React.FC = () => (
  <div className="p-xl h-[100vh] bg-neutral-100">
    <h1 className="font-bold text-primary mb-md">Liste des 10-0 Babyfoot</h1>
    <div className="flex w-full">
      <div className="w-1/2">
        <Table
          itemsPerPage={12}
          columnConfigs={[
            { name: 'Date', columnKey: 'date' },
            {
              name: 'Team 1',
              columnKey: 'team1',
              renderValue(team: any) {
                const members = team.slice(0, -1) as string[];
                const member1 = members[0];
                const member2 = members[1];
                return (
                  <div>
                    <span className="px-3">{member1}</span>
                    {member2 && (
                      <>
                        <span className="px-3"> - </span>
                        <span className="px-3">{member2}</span>
                      </>
                    )}
                  </div>
                );
              },
            },
            {
              name: 'Team 2',
              columnKey: 'team2',
              renderValue(team: any) {
                const members = team.slice(0, -1) as string[];
                const member1 = members[0];
                const member2 = members[1];
                return (
                  <div>
                    <span className="px-3">{member1}</span>
                    {member2 && (
                      <>
                        <span className="px-3"> - </span>
                        <span className="px-3">{member2}</span>
                      </>
                    )}
                  </div>
                );
              },
            },
            {
              name: 'Score',
              columnKey: 'team1',
              renderValue(team, zgegData) {
                const scoreTeam1 = team?.slice(-1)[0] as number;

                const scoreTeam2 = zgegData.team2.slice(-1)[0] as number;
                return (
                  <div>
                    <span className="px-3">{scoreTeam1}</span>
                    {scoreTeam2 >= 0 && (
                      <>
                        <span className="px-3"> - </span>
                        <span className="px-3">{scoreTeam2}</span>
                      </>
                    )}
                  </div>
                );
              },
            },
          ]}
          items={zgegData}
        />
      </div>
      <div className="flex flex-col w-1/2 ml-3xl">
        <div className="mb-xxl">
          <div className="text-ds-h4 mb-xxl font-bold">Zgeg podium</div>
          <div className="flex items-end">
            <div className={classNames(classNamesZgegPodium, zgegPodiumSize.second)}>
              {worst3[1]}
            </div>
            <div className={classNames(classNamesZgegPodium, zgegPodiumSize.first)}>
              {worst3[0]}
            </div>
            <div className={classNames(classNamesZgegPodium, zgegPodiumSize.third)}>
              {worst3[2]}
            </div>
          </div>
        </div>
        <div className="text-ds-h4 mb-xxl font-bold">100% PRO podium</div>
        <div className="flex items-end">
          <div className={classNames(classNamesZgegPodium, zgegPodiumSize.second)}>{top3[1]}</div>
          <div className={classNames(classNamesZgegPodium, zgegPodiumSize.first)}>{top3[0]}</div>
          <div className={classNames(classNamesZgegPodium, zgegPodiumSize.third)}>{top3[2]}</div>
        </div>
      </div>
    </div>
    <FloatingScore date={zgegData[0].date} winners={zgegData[0].team1} losers={zgegData[0].team2} />
  </div>
);
